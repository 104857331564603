import Vue from 'vue';
import axios from 'axios'

const state = {
    MRData: [],
    requirement_group: []
}

const mutations = {
    SET_MR_DATA(state, data) {
        Vue.set(state, 'MRData', data);
    },
    SET_REQUIREMENT_GROUP_DATA(state, data) {
        console.log('miutation data -=> ', data.requirements_groups);
        state.requirement_group = []
        data.requirements_groups.forEach(elm => {
            state.requirement_group.push({ name: item, technical_specifications: [], status: false })
        });
    }
}

const actions = {
    saveIDC({commit}, payload) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/quickaction/bulk`, payload)
    },
    quickAction({commit}, payload) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/quickaction/bulk`, payload);
    },
    getPublishTimeline({commit}, param) {
        if (param.category == 'packages') {
            return axios.get(`${process.env.VUE_APP_API}/lhs/publish/timeline?package_id=${param.package_id}`);    
        } else {
            return axios.get(`${process.env.VUE_APP_API}/lhs/publish/timeline?mr_id=${param.mr_id}`);
        }
    },
    getTechspecSuggestions({commit}, mrId) {
        return axios.get(`${process.env.VUE_APP_API}/template/suggest?mr_id=${mrId}`);
        
    },
    getTags({commit}, mrId) {
        return axios.get(`${process.env.VUE_APP_API}/mr/taglist?mr_id=${mrId}`);
    },
    updateMR({commit}, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/mr/row/bulk`, params);
    },
    getMRCategory () {
        return axios.get(`${process.env.VUE_APP_API}/categories`);
    },
    getMRType ({commit}, category) {
        return axios.get(`${process.env.VUE_APP_API}/item/types?category=${category}`);
    },
    getDocType ({commit}, typeId) {
        return axios.get(`${process.env.VUE_APP_API}/document/types?item_type_id=${typeId}&page=mr`);
    },
    getFullSnippet ({ state }, param) {
        return axios.post(`${process.env.VUE_APP_API}/mr/full_snippet`, param);
    },
    getAllContexts ({ state }, param) {
        return axios.post(`${process.env.VUE_APP_API + '/mr/multiple_context?mr_id=' + this.$route.query.mr_id + '&req_group_name=' + param.reqName + '&tech_spec_name=' + param.val['1'] + '&tag_id=' + param.encodeTagId}`);
    },
    selectOtherContext ({ state }, param) {
        return axios.put(`${process.env.VUE_APP_API}/mr/multiple_context`, param);
    },
}

const getters = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
import Vue from 'vue';
import axios from 'axios'

const state = {
    subpackages: [],
}

const mutations = {
    SET_TAGS (state, subpackages) {
        Vue.set(state, 'tags', subpackages);
    },
}

const actions = {
    listSubPackages({ commit }, params) {
        return axios.get(`${process.env.VUE_APP_API}/package/sub_packages?package_id=${params}`);
    },
    requirementgroups({ commit }, params) {
        return axios.get(`${process.env.VUE_APP_API}/package/requirementgroups?package_id=${params}`);
    },
    getItemsTypes ({commit}, category) {
        return axios.get(`${process.env.VUE_APP_API}/package/list`);
    },
    createRequisition ({commit}, payload) {
        return axios.post(`${process.env.VUE_APP_API}/mr/requisition`,payload);
    },
    addFilesPackage ({commit}, payload) {
        return axios.post(`${process.env.VUE_APP_API}/package/files`,payload, {
            headers: {
                'traceparent': `${payload.traceparent}` ,
            },
        });
        
    },
    selectOtherContext ({ state }, param) {
        return axios.put(`${process.env.VUE_APP_API}/package/multiple_context`, param);
    },
    selectOtherContextOffer ({ state }, param) {
        return axios.put(`${process.env.VUE_APP_API}/package/offer/multiple_context`, param);
    },
    setTagList({commit}, mrId) {
        axios({
            url: `${process.env.VUE_APP_API}/package/sub_packages?package_id=${mrId}`,
            method: 'get'
        }).then((res) => {
            commit('SET_TAGS', res.data.tags);
        })
    },
}

const getters = {
    getSubPackages () {
        return state.subpackages;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
import Vue from 'vue';
import axios from 'axios'

const state = {
}

const mutations = {
}

const actions = {
    async SUBMIT_DATA({commit},payload) {
        return await axios({
            url: process.env.VUE_APP_API + '/offer/vendor_input',
            method: 'post',
            data: payload
        })
    },
    async REMOVE_DATA({ commit }, payload) {
        return await axios({
            url: process.env.VUE_APP_API + '/offer/vendor_input',
            method: 'post',
            data: payload
        })
    },
    async FILE_LIST({commit}, payload) {
        return await axios({
            url: process.env.VUE_APP_API + '/mr/files?' + payload,
            method: 'get'
        })
    }
}

const getters = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
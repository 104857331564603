export const actionNames = Object.freeze({
    ASSIGN: 'assign',
});
export const IDCStatus =  Object.freeze({
    notPublished: 'Not Published',
    awaitingResponse: 'Awaiting Response',
    responseRecieved: 'Response Recieved',
    IDCRaised: 'IDC Raised',
    publishedToRE: 'Published to RE',

});
export const IDCCategory =  Object.freeze({
    generalQuery: 'General Query - Assignee',
    offerExtract: 'Offer Extract',
    general: 'General Query'
}) 
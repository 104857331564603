<template>

  <v-autocomplete v-if="showEvalIcon || piping" :search-input.sync="search" :disabled="!deleteCurrentTag.length"
    item-disabled="tag_disabled" v-model="dropdownSelectedTags" multiple elevation="0" :items="deleteCurrentTag"
    label="Select Applicable Tags" item-text="tag_id" item-value="tag_uuid" small-chips solo class="cus-autocomplete" @change="getSelectedTags()">
    <template v-slot:prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon :color="dropdownSelectedTags.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Select All
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:selection="{ index }">
      <v-chip small v-if="dropdownSelectedTags.length == 1">{{ index + 1 }} tag selected</v-chip>
      <v-chip small v-if="index == (dropdownSelectedTags.length - 1) && dropdownSelectedTags.length > 1">{{ index + 1 }}
        tags selected</v-chip>
    </template>
    <template v-slot:item="{ props, item }">
      <v-list-item class="d-flex">
        <div>
          <v-simple-checkbox color="primary" :value="isSelected(item)" @click="toggleItem(item)" />
        </div>
        <div class="font-weight-bold mb-2 mb-0">
          {{ item.tag_id }}
          <sup v-if="item.recently_added && !item.label" style="font-size:11px !important"
            class="orange darken-1 p-1 py-0 white--text rounded">New</sup>
          <sup :title="item.label" v-if="item.label" style="font-size:11px !important"
            class="grey darken-1 p-1 py-0 white--text rounded">Updated</sup>
          <h6 class="small m-0">{{ item.tag_name }}</h6>
        </div>
      </v-list-item>
    </template>
    <template v-slot:append-item>
      <v-list-item style="opacity:0.8" class="d-flex" v-for="(diabledTags, idx) in taglists" :key="idx">
        <div v-if="(diabledTags.tag_disabled && !search > 0)" class="d-flex">
          <div>
            <v-simple-checkbox color="primary" disabled />
          </div>
          <div class="">{{ diabledTags.tag_id }}
            <sup style="font-size:11px !important" class="red darken-1 p-1 py-0 white--text rounded mx-1">Disabled</sup>
            <h6 class="small m-0">{{ diabledTags.tag_name }}</h6>
          </div>
        </div>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>

export default {
  props: ['taglists', 'showEvalIcon', 'currentTag', 'checkedTags', 'piping'],
  data() {
    return {
      deleteCurrentTag: [],
      dropdownSelectedTags: [],
      search: ''
    }
  },
  components: {
  },
  created() {
  },
  computed: {
    selectedAllTags() {
      return this.dropdownSelectedTags.length === this.deleteCurrentTag.length
    },
    selectedSomeTags() {
      return this.dropdownSelectedTags.length > 0 && !this.selectedAllTags
    },
    icon() {
      if (this.selectedAllTags) return 'mdi-close-box'
      if (this.selectedSomeTags) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  watch: {
    checkedTags(to, from) {
      if (to.length != from.length) {
        this.customFilter()
      }
    }

  },
  mounted() {
      this.customFilter() 
  },
  methods: {
    customFilter() {
      if (this.showEvalIcon || this.piping) {
        this.deleteCurrentTag = this.$props.taglists.slice()
        this.$props.taglists.forEach((ite) => {
          if (ite.tag_disabled || (ite.tag_uuid == this.$props.currentTag?.tag_uuid)) {
            this.deleteCurrentTag.forEach((items, i) => {
              if (ite.tag_uuid == items.tag_uuid) {
                this.deleteCurrentTag.splice(i, 1)
              }
            })
          }
        })
        if (this.checkedTags) {
          this.dropdownSelectedTags = this.deleteCurrentTag.filter(item => (this.checkedTags.includes(item.tag_uuid)))
          if (this.$route.name != 'Offer Listing') {
            this.getSelectedTags()
          }
        }
      } else {
        this.$props.taglists.forEach((ite) => {
          this.deleteCurrentTag.push(ite[0])
        })
      }
    },
    isSelected(item) {
      return this.dropdownSelectedTags.includes(item);
    },
    toggleItem(item) {
      if (this.dropdownSelectedTags.includes(item)) {
        this.dropdownSelectedTags = this.dropdownSelectedTags.filter(v => v !== item);
      } else {
        this.dropdownSelectedTags.push(item);
      }
      this.getSelectedTags()
    },
    toggle() {
      if (this.selectedAllTags) {
        this.dropdownSelectedTags = []
      } else {
        this.dropdownSelectedTags = this.deleteCurrentTag.slice()
      }
      this.getSelectedTags()
    },
    getSelectedTags() {
      this.$emit('getSelectedTags', this.dropdownSelectedTags)
    }
  }
}
</script>
<style lang="scss">

</style>

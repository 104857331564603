import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import auth from './auth';
import notification from './notification';
import tqRevamp from './tqRevamp';
import tqVendor from './tqVendor';
import profile from './profile';
import IDC from './IDC';
import shared from './shared';
import MRExtraction from './MRExtraction';
import insights from './insights'
import offerEvaluation from './offerEvalution'
import search from './search'
import vendorTBE from './vendorTBE';
import vendorAuth from './vendorAuth';
import TBE from './TBE';
import Dash from './Dashboard';
import bulkMaterial from './bulkMaterial';
import Packages from './Packages';
import requisition from './requisition';
import bidTab from './bidTab';
import crs from './CRS'


// const NAMESPACE = "65f9af5d-f23f-4065-ac85-da725569fdcd";
const instance = axios.create({
  baseURL: process.env.VUE_APP_API
})

Vue.use(Vuex)

export default new Vuex.Store({
  instance,
   modules: {
    auth,
    notification,
    tqRevamp,
    tqVendor,
    profile,
    IDC,
    shared,
    MRExtraction,
    insights,
    offerEvaluation,
    search,
    vendorTBE,
    vendorAuth,
    TBE,
    Dash,
    bulkMaterial,
    Packages,
    requisition,
    bidTab,
    crs
  },
})

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import router from '@/router';
import VueCookie from 'vue-cookie';

const $cookie = VueCookie;
const state = {
    user: null,
    sidebar: false,
    uuidv44: null,
    vendorDel: {},
}

const mutations = {
    SET_USER_DATA(state, userData) {
        state.user = userData
        const key = 'mm_auth_key';
        $cookie.set(key, userData.jwt);
        axios.defaults.headers.common['Authorization'] = `Bearer ${$cookie.get(key)}`
        axios.defaults.headers.common['x-request-id'] = uuidv4()
        axios.defaults.headers.common['x-b3-traceid'] = uuidv4()

        const modifiedObject = Object.assign({}, userData);
        delete modifiedObject['jwt'];
        localStorage.setItem('user', JSON.stringify(modifiedObject))
    },
    CLEAR_USER_DATA() {
        localStorage.removeItem("user")
        localStorage.clear()
        location.reload()
        $cookie.delete('mm_auth_key');
    },
    SIDE_BAR(state, value) {
        state.sidebar = value
    },
}

const actions = {
    register({ commit }, credentials) {
        return axios
            .post(`${process.env.VUE_APP_API}/password/set?u=${localStorage.getItem('userID')}&t=${localStorage.getItem('userToken')}`, credentials)
            .then(({ data }) => {
                // console.log('user data is', data)
                commit('SET_USER_DATA', data)
            })
    },
    login({ commit }, credentials) {
        console.log('cred check');
        return axios
            .post(`${process.env.VUE_APP_API}/login`, credentials)
            .then(({ data }) => {
                // console.log(data)
                commit('SET_USER_DATA', data)
                // const notification = {
                //     type: 'success',
                //     message: 'logged In!'
                // }
                // dispatch('notification/add', notification, { root: true })
            })
            .catch(error => {
                // const notification = {
                //   type: 'error',
                //   message: 'There was a problem creating your event: ' + error.message
                // }
                // dispatch('notification/add', notification, { root: true })
                throw error
            })
    },
    forgotpassword({ commit }, credentials) {
        return axios
            .post('/auth/forgot-password', credentials)
            .then(({ data }) => {
                //commit('SET_USER_DATA', data)
                //   dispatch('notification/add', notification, { root: true })
                //   })
                //   .catch(error => {
                //     const notification = {
                //     type: 'error',
                //     message: 'There was a problem: ' + error.message
                //   }
                //   dispatch('notification/add', notification, { root: true })
                //   throw error
            })
    },
    logout({ commit }) {
        return axios
            .post(`${process.env.VUE_APP_API}/logout`)
            .then((response) => {
                if (response.data.status) {
                    commit('CLEAR_USER_DATA')
                    router.replace({ path: '/login' })
                }
                if (response.data.status === 401) {
                    commit('CLEAR_USER_DATA')
                    router.replace({ path: '/login' })
                }
                else {
                    commit('CLEAR_USER_DATA')
                    router.replace({ path: '/login' })
                }
            })
            .catch(err => {
                commit('CLEAR_USER_DATA')
                router.replace({ path: '/login' })
            })
    },
    rolePrediction({ commit }, credentials) {
        return axios
            .post('/inference/rolePrediction?industry_id=5ceb8d8f005fb9001c582f95', credentials)
            .then((response) => {
                // console.log(credentials)
                localStorage.setItem("role_prediction_id", response.data.role_prediction_id);
                // console.log(response.data.role_prediction_id)
            });
        // .then(({ res }) => {
        //   localStorage.setItem('role_prediction_id', response.role_prediction_id)
        //   console.log('user data is', data)
        //   // commit('SET_USER_DATA', data)
        // })
    },
    getitemprogress({ commit, dispatch }, item) {
        console.log('item-----fromstore', item)
        let progressMR = item.item
        return axios
            .get(`${process.env.VUE_APP_API}/mr/progress?mr_id=${progressMR._id}`)
            .then(({ data }) => {
                console.log(data)
                if (data.extraction_status == 'Failed') {
                    const notification = {
                        type: 'error',
                        message: 'Sorry! Extraction for ' + progressMR.name + ' has been failed.'
                    }
                    dispatch('notification/add', notification, { root: true })
                }
                if (data.extraction_status == 'Completed') {
                    console.log("print Success");
                    const notification = {
                        type: 'success',
                        message: 'Requisition has been completed for  ' + progressMR.name + '.'
                    }
                    dispatch('notification/add', notification, { root: true })
                }
                // if(data.extraction_status == 'In-progress'){
                //   const notification = {
                //     type: 'warning',
                //     message: 'Requisition for  ' + progressMR.name + ' is in-progress.'
                //   }
                //   dispatch('notification/add', notification, { root: true })
                // }
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'Error: ' + error.message
                }
                dispatch('notification/add', notification, { root: true })
                throw error
            })
    },
}

const getters = {
    loggedIn(state) {
        return !!state.user
    },
    user(state) {
        return state.user
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
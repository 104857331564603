import axios from 'axios'

export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        $sh_upload_file({commit}, payload) {
            return axios({
                url: process.env.VUE_APP_CRS + '/file/upload',
                method: 'post',
                data: payload
            })
        }
    }
}
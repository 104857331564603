import Vue from 'vue';
import axios from 'axios'

const state = {
    groups: {},
    tagsList: [],
    tagsListUuid:[],
    tabularData:{},
    loading: false,
    currentPage: 1,
    tagsPerPage: 20,
    totalTags: 0,
}

const mutations = {
    SET_TAGS_TECH_DATA (state, data) {
        Vue.set(state, 'tabularData', data);
    },
    SET_REQUIREMENT_GROUPS (state, data) {
        Vue.set(state, 'groups', data);
    },
    SET_TAGS_UUID(state, data) {
        Vue.set(state, 'tagsListUuid', data);
    },
    SET_TAGS_LIST(state, data) {
        Vue.set(state, 'tagsList', data);
    },
    SET_LOADING(state, isLoading) {
        state.loading = isLoading;
    },
    SET_TOTAL_TAGS(state, total) {
        state.totalTags = total;
    },
    SET_CURRENT_PAGE(state, page) {
        state.currentPage = page;
    },
    RESET_STATE(state) {
        state.groups = {};
        state.tagsList = [];
        state.tagsListUuid = [];
        state.tabularData = {};
        state.loading = false;
        state.totalTags = 0;
        state.tagsPerPage = 20;
    },
}

const actions = {
    setLoading({ commit }, loading) {
        commit('SET_LOADING', loading);
    },
    checkActions ({ state }, params) {
        return axios.post(`${process.env.VUE_APP_API}/offer/action/check`, params);
    },
    getVendorList ({ state }, params) {
        return axios.get(`${process.env.VUE_APP_API}/offer/names?${params}`);
    },
    checkBlankInfer ({ state }, params) {
        return axios.post(`${process.env.VUE_APP_API}/offer/inference/check`, params);
    },
    lockTBE ({ state }, params) {
        return axios.post(`${process.env.VUE_APP_API_MSC}/tbe/lock`, params);
    },
    unLockTBE ({ state }, params) {
        return axios.post(`${process.env.VUE_APP_API_MSC}/tbe/unlock`, params);
    },
    downloadFile ({ state }, params) {
        return axios.get(`${process.env.VUE_APP_API}/download/url?file=${params}`);
    },
    saveTBERev ({ state }, params) {
        return axios.get(`${process.env.VUE_APP_API_MSC}/offer/comparison/export?${params.offers}&mr_id=${params.mr_id}&revision_name=${params.revision_name}&is_revision=${params.isRev}`);
    },
    trackTBE ({ state }, params) {
        return axios.get(`${process.env.VUE_APP_API_MSC}/job?job_id=${params.id}&is_revision=${params.isRev}`);
    },
    exportTBE ({ state }, params) {
        return axios.get(`${process.env.VUE_APP_API_MSC}/offer/comparison/export?${params.offers}&format=${params.format}&is_revision=${params.isRev}`);
    },
    deleteTBERevHistory ({ state }, params) {
        return axios.get(`${process.env.VUE_APP_API_MSC}/revision/list/delete?id=${params}`);
    },
    downloadTBERevHistory ({ state }, params) {
        return axios.get(`${process.env.VUE_APP_API_MSC}/revision/list/download?id=${params.id}&export_format=${params.format}`);
    },
    getTBERevHistory ({ state }, params) {
        return axios.get(`${process.env.VUE_APP_API_MSC}/revision/list?id=${params}`);
    },
    saveTemplate ({ state }, params) {
        return axios.post(`${process.env.VUE_APP_API}/template/save`, params);
    },
    getShortSnippet ({ state }, params) {
        return axios.get(`${process.env.VUE_APP_API}/download/url?file=${params}`);
    },
    getFullSnippet ({ state }, params) {
        return axios.post(`${process.env.VUE_APP_API}/offer/full_snippet`, params);
    },
    viewClassification({state},params){
        return axios.get(`${process.env.VUE_APP_API}/offer/classification?offer=${params.id}&filter_by=${params.tagType}`);
    },
    downloadLHSFile({state},params){
        return axios.get(`${process.env.VUE_APP_API}/mr/download?mr_id=${params.id}`);
    },
    downloadDocumentList({state}, params){
        return axios.get(`${process.env.VUE_APP_API}/offer/names?id=${params.id}`);
    },
    saveInference({state}, params){
        return axios.post(`${process.env.VUE_APP_API_MSD}/infer/row/bulk`, params);
    },
    getTagsProgress ({ state }, params) {
        return axios.post(`${process.env.VUE_APP_API}/mr/tags/progress`, params);
    },
    getTechspecSuggestions({commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/template/suggest?mr_id=${params.mr_id}&group_name=${params.group_name}`);
    },
    addTechSpec ({ state }, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/mr/row`, params);
    },
    checkTechSpec ({ state }, params) {
        return axios.get(`${process.env.VUE_APP_API}/specification/check?mr_id=${params.mr_id}&group=${params.regGroup}&tech_spec=${params.techSpec}`);
    },
    selectOtherContext ({ state }, param) {
        return axios.put(`${process.env.VUE_APP_API}/mr/multiple_context`, param);
    },
    updateSpec({ commit }, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/mr/row/bulk`, params);
    },
    triggerContexts({ commit }, params) {
        return axios.get(`${process.env.VUE_APP_API}/mr/trigger/multiple_context?mr_id=${params.mr_id}&req_group_name=${params.regGroup}&tech_spec_name=${params.techSpec}&tag_id=${params.tag_id}`);
    },
    getContexts({ commit }, params) {
        return axios.get(`${process.env.VUE_APP_API}/mr/multiple_context?mr_id=${params.mr_id}&req_group_name=${params.regGroup}&tech_spec_name=${params.techSpec}&tag_id=${params.tag_id}`);
    },
    async updateMR({dispatch}, params) {
        return await axios.post(`${process.env.VUE_APP_API_MSD}/mr/row/bulk`, params);
    },
    async getUnitsTech ({dispatch }, params) {
        return await axios.get(`${process.env.VUE_APP_API}/specification/units?mr_id=${params.mr_id}&tech_spec=${encodeURIComponent(params.tech_spec)}`);
    },
    getColumnuUniqueValues({dispatch}, params) {
        if(params.pageType === 'bidtabLHS') {
            return axios.post(`${process.env.VUE_APP_API}/mr/values`, params)
        }
        return axios.post(`${process.env.VUE_APP_API}/offer/values`, params)
    },
    downloadLHSTimeline({dispatch}, mrId) {
        return axios.get(`${process.env.VUE_APP_API}/mr/timeline/export?mr_id=${mrId}`)
    },
    async getFilteredData({dispatch,commit}, params) {
        let resp;
        if(params.pageType === 'bidtabLHS') {
            params['mr_id'] = params.mrId;
            resp = await axios.post(`${process.env.VUE_APP_API}/mr/values/filter`, params);
        } else if(params.pageType === 'vendor compare') {
            let newParams = JSON.parse(JSON.stringify(params));
            newParams['offer_id'] = params.offerId[0];
            resp = await axios.post(`${process.env.VUE_APP_API}/offer/values/filter`, newParams)
        } else {
            params['offer_id'] = params.offerId || params.offer_id;
            resp = await axios.post(`${process.env.VUE_APP_API}/offer/values/filter`, params)
        }
        params['matched_tags'] = resp.data.data.matched_tags;
        let selectedFilterTags = state.tagsList.filter(tag => resp.data.data.matched_tags.find(item => item == tag.tag_uuid));
        console.log('selectedFilterTags',selectedFilterTags);
        commit('SET_TAGS_LIST', selectedFilterTags);
        commit('SET_TOTAL_TAGS', selectedFilterTags.length);
        await dispatch('getTechnicalSpecification', params);
    },
    async updateTBEData({state,commit},incomingData){
        let structuredData = JSON.parse(JSON.stringify(state.tabularData));
        if(incomingData.page === 'LHS'){
            structuredData.rows.forEach((row,index) => {
                row.values.forEach((item, index) => {
                    if(incomingData.tag_ids.length > 0){
                        if(incomingData.tech_specs[0].technical_description === item[1]){
                            console.log('awesome');
                        }
                    }else if(item.row_uuid === incomingData.row_uuid && item.tag_uuid === incomingData.tag_uuid){
                        item = incomingData.data
                    }
                });
            });
        }else if(incomingData.page === 'RHS'){
            structuredData.rows.forEach(row => {
                row.values.forEach((item, index) => {
                    item.vendors.forEach((vendor,vIndex) => {
                        if(vendor.data.row_uuid === incomingData.row_uuid){
                            row.values[index].vendors[vIndex].data = incomingData.data;
                        }
                    });
                });
            });
        }else if(incomingData.page === 'revision compare'){
            structuredData.rows.forEach(row => {
                row.values.forEach((item, index) => {
                    item.revisions.forEach((rev,vIndex) => {
                        if(rev?.data?.row_uuid === incomingData?.row_uuid){
                            row.values[index].revisions[vIndex].data = incomingData.data;
                        }
                    });
                });
            });
        }else if(incomingData.page === 'revision inference'){
            structuredData.rows.forEach(row => {
                row.values.forEach((item, index) => {
                    item.revisions.forEach((rev,vIndex) => {
                        if(rev?.inference_row_uuid === incomingData.inference_row_uuid){
                            row.values[index].revisions[vIndex] = incomingData.data;
                        }
                    });
                });
            });
        }
        commit('SET_TAGS_TECH_DATA', structuredData);
    },
    async updateTabularData({ state, commit, dispatch }, updatedData) {
        let structuredData = JSON.parse(JSON.stringify(state.tabularData));   
        if (updatedData.storeAction === 'idcUpdate') {
            structuredData.rows.forEach(row => {
                row.values.forEach(item => {
                    updatedData.data.forEach(actions =>{
                        if (updatedData.data?.length > 1 && item[1] === actions.tech_spec && item.tag_uuid === actions.tag_id) {
                            Vue.set(item, 'action', actions.action);
                        }
                    });
                    if (item.row_uuid === updatedData.data[0].row_uuid && item.tag_uuid === updatedData.data[0].tag_id) {
                        Vue.set(item, 'action', updatedData.data[0].action);
                    }
                });
            });
        }
    
        structuredData.rows.forEach(row => {
            row.values.forEach(item => {
                if (updatedData.storeAction === 'bulkActionsUpdate') {
                    item.vendors.forEach(vendor => {
                        updatedData.data.forEach(updatedItem => {
                            if (item.tag_uuid === updatedItem.tag_id && vendor.data['row_uuid'] === updatedItem.row_uuid) {
                                Vue.set(vendor.data, 'action', vendor.data.action || {});
                                Vue.set(vendor.data.action, 'action', updatedItem.action);
                            }
                        });
                    });
                } else if (updatedData.storeAction === 'bulkInference' || updatedData.storeAction === 'vendor res bulkInference') {
                    if(updatedData.tag_ids.includes(item.tag_uuid)){
                        item.vendors.forEach(vendor => {
                            updatedData.technical_descriptions.forEach(updatedItem => {
                                const vendorTechSpec = vendor.data[1]?.trim().toLowerCase();
                                const match = updatedItem.specs.some(spec => spec.trim().toLowerCase() === vendorTechSpec);
                                if (match) {
                                    const actionData = typeof updatedData.action === 'object' && updatedData.action !== null ? updatedData.action : {};
                                    if(updatedData.storeAction === 'vendor res bulkInference'){
                                        Vue.set(vendor.data, 3, vendor.data.vendor_inp_re);
                                    }else{
                                        Vue.set(vendor.data, 3, updatedData.inference_value);
                                    }
                                    if(updatedData.action){
                                        Vue.set(vendor.data, 'action', actionData);
                                        Vue.set(vendor.data.action, 'action', updatedData.action);
                                        console.log('vendor.data.action', vendor.data.action);
                                    }else{
                                        const currentAction = vendor.data.action;
                                        if (typeof currentAction === 'object' && currentAction !== null) {
                                            if (currentAction.action !== 'add to tq' && currentAction.action !== 'assign') {
                                                Vue.set(vendor.data, 'action', 'reset');
                                            }
                                        } else if (currentAction !== 'add to tq' && currentAction !== 'assign') {
                                            Vue.set(vendor.data, 'action', 'reset');
                                        }                                    
                                    }
                                }
                            });
                        });
                    }
                }
    
                if (item.vendors && (item.tag_uuid === updatedData.tag_uuid || item.tag_uuid === updatedData.data?.[0]?.tag_id)) {
                    item.vendors.forEach(vendor => {
                        if (updatedData.storeAction === 'inferenceUpdate' && vendor.data.row_uuid === updatedData.row_uuid) {
                            Vue.set(vendor.data, 3, updatedData[3]);
                        }if (updatedData.storeAction === 'actionsUpdate' && (vendor.data.row_uuid === updatedData.data[0].row_uuid || vendor.data.inference_row_uuid === updatedData.data[0].row_uuid) ) {
                            if(!vendor.data?.action?.action) {
                                vendor.data['action'] = {action: {}}
                            }
                            Vue.set(vendor.data.action, 'action', updatedData.data[0].action);
                            if(updatedData.data[0].comment !== 'No comment' && (updatedData.data[0].action === 'accept' || updatedData.data[0].action === 'confirmcde')){
                                Vue.set(vendor.data, 3, updatedData.data[0].comment);                                        
                            }
                        }
                    });
                }

                if( updatedData.pageType === 'rev-actionsUpdate') {
                    if(updatedData.storeAction === 'actionsUpdate' && item.revisions && updatedData.data?.length > 0){
                        item.revisions.forEach((rev) => {
                            updatedData.data.forEach((actions) =>{
                                if (rev.inference_row_uuid === actions.row_uuid) {
                                    if(!rev?.action?.action) {
                                        rev['action'] = {action: {}}
                                    }
                                    Vue.set(rev.action, 'action', actions.action);
                                    if(updatedData.data[0].comment !== 'No comment'){
                                        rev.inference = updatedData.data[0].comment
                                    }
                                }
                            });
                        })
                    }
                }
            });
        });
    
        if (updatedData.storeAction === 'addspec') {
            const newItem = {
                0: updatedData.data.requirement_group,
                1: updatedData.data.technical_description,
                2: updatedData.data.specification,
                row_uuid: updatedData.data.row_uuid,
                units: updatedData.data.units,
                units_show: updatedData.data.units_show,
                units_status: updatedData.data.units_status,
            };
            let found = false;
            structuredData.rows.forEach(row => {
                row.values.forEach(grp => {
                    if (grp[0] === updatedData.data.requirement_group && !found) {
                        found = true;
                        let params = {
                            mrId: updatedData.mrId,
                            pageType: 'LHS'
                        }
                        dispatch('getTagList', params);
                    }
                });
            });
            if (!found) {
                structuredData.requirementGroups.push({
                    name: updatedData.data.requirement_group,
                    count: '0'
                });
                structuredData.allKeys.push(newItem);
            }
        }
        commit('SET_TAGS_TECH_DATA', structuredData);
    },
    async getTagList({ commit, dispatch}, params) {
        commit('SET_LOADING', true);
        let tags = [];
        let offerId = Array.isArray(params.offerId) ? params.offerId[0] : params.offerId;
        if(params.pageType === 'vendor') {
            tags =  await axios.get(`${process.env.VUE_APP_API}/mr/taglist?offer_id=${params.offerId}&token=${params.token}&vendor_id=${params.vendor_id}`);
        } else if(params.pageType === 'RHS' || params.pageType === 'vendor compare' || params.pageType === 'revision compare') {
            tags = await axios.get(`${process.env.VUE_APP_API}/mr/taglist?offer_id=${offerId}`);
        } else {
            tags = await axios.get(`${process.env.VUE_APP_API}/mr/taglist?mr_id=${params.mrId}`);
        }
        commit('SET_TAGS_LIST', tags.data.tags);
        commit('SET_TOTAL_TAGS', tags.data.tags.length);
        commit('SET_CURRENT_PAGE', 1);
        await dispatch('getRequirementGroups', params);
        return tags.data.tags;
    },
    async getRequirementGroups ({ commit, dispatch}, params) {
        let groups = [];
        let offerId = Array.isArray(params.offerId) ? params.offerId[0] : params.offerId;
        if(params.pageType === 'vendor') {
            groups = await axios.get(`${process.env.VUE_APP_API}/mr/requirementgroups?offer_id=${params.offerId}&vendor_id=${params.vendor_id}&token=${params.token}`);
        } else if(params.pageType === 'RHS' || params.pageType === 'vendor compare' || params.pageType === 'revision compare' || params.pageType === 'bidtabRHS') {
            groups = await axios.get(`${process.env.VUE_APP_API}/mr/requirementgroups?offer_id=${offerId}`);
        } else {
            groups = await axios.get(`${process.env.VUE_APP_API}/mr/requirementgroups?mr_id=${params.mrId}`);
        }
        commit('SET_REQUIREMENT_GROUPS', groups.data);
        await dispatch('getTechnicalSpecification', params);
    },
    async getTechnicalSpecification({dispatch}, params) {
        let techSpec;
        let payload = {}
        
        const start = (state.currentPage - 1) * state.tagsPerPage;
        const end = state.currentPage * state.tagsPerPage;
        // payload.tag_ids = state.tagsList.filter(tag => !tag.tag_disabled).slice(start, end).map(tag => tag.tag_uuid);
        payload.tag_ids = state.tagsList.slice(start, end).map(tag => tag.tag_uuid);
        payload.requirement_groups = state.groups.requirements_groups
        payload.mr_id = params.mrId
        if(params.pageType === 'vendor') {
            payload['token'] = params.token;
            payload['vendor_id'] = params.vendor_id;
        }
        if(Array.isArray(params.offerId)){
            payload.offer_ids = params.offerId
        }else{
            payload.offer_id = params.offerId
        }
        if(params.pageType === 'RHS' || params.pageType === 'bidtabRHS') {
            techSpec = await axios.post(`${process.env.VUE_APP_API}/offer/specification/v2`, payload);
        } else if(params.pageType === 'vendor compare') {
            techSpec = await axios.post(`${process.env.VUE_APP_API_MSC}/offer/comparison/v2`, payload);
        } else if(params.pageType === 'vendor') {
            techSpec = await axios.post(`${process.env.VUE_APP_API}/offer/specification/v2`, payload);
        } else if(params.pageType === 'revision compare') {
            techSpec = await axios.post(`${process.env.VUE_APP_API_MSC}/revision/comparison/v2`, payload);
        } else {
            techSpec = await axios.post(`${process.env.VUE_APP_API}/mr/technicaldescriptions/v2`, payload);
        }
        await dispatch('dataForTabularView', techSpec.data.data);
    },
    async dataForTabularView({ commit }, data) {
        let tabularData = {};
        if (data && data.tags_data) {
            const tags = Object.keys(data.tags_data);
            if (tags?.length > 0) {
                const firstTag = data.tags_data[tags[0]];
                tabularData.allKeys = firstTag.map(item => ({ ...item, selected: false }));
                const groups = {};
                firstTag.forEach(item => {
                    if (!groups[item[0]]) {
                        groups[item[0]] = 0;
                    }
                    groups[item[0]]++;
                });
                tabularData.requirementGroups = Object.keys(groups).map(group => ({
                    name: group,
                    count: groups[group],
                    selected: false
                }));
                let tagInfo = null
                tabularData.rows = tags.flatMap(tag => {
                    const tagData = data.tags_data[tag];
                    const values = tagData.map(item => { 
                        const matchedTag = state.tagsList.find(tagFromList => tagFromList.tag_uuid === item.tag_uuid)
                        if (matchedTag) {
                            tagInfo = matchedTag;
                        }
                        return {...item, iconsVisible: false} 
                    });
                    const vendorCount = values[0].vendors ? values[0].vendors?.length : (values[0].revisions ? values[0].revisions?.length: 0);
                    return {
                        tagInfo,
                        tagNo: tag,
                        values,
                        vendorCount
                    };
                });
            }
        } else {
            console.error("data or data.tags_data is undefined or null");
        }
        commit('SET_TAGS_TECH_DATA', tabularData);
        commit('SET_LOADING', false);
    },
    getColumnInfo({ commit }, params) {
        if(params.pageType === 'vendor') {
            return axios.get(`${process.env.VUE_APP_API}/mr/headers?mr_id=${params.mrId}&token=${params.token}&vendor_id=${params.vendor_id}`);
        }
        return axios.get(`${process.env.VUE_APP_API}/mr/headers?mr_id=${params.mrId}`);
    },
    loadNextPage({ state, commit, dispatch }, params) {
        // state.tabularData = {}
        if ((state.currentPage * state.tagsPerPage) < state.totalTags) {
          commit('SET_CURRENT_PAGE', state.currentPage + 1);
          dispatch('getTechnicalSpecification', params);
        }
    },
    loadPreviousPage({ state, commit, dispatch }, params) {
        // state.tabularData = {}
        if (state.currentPage > 1) {
            commit('SET_CURRENT_PAGE', state.currentPage - 1);
            dispatch('getTechnicalSpecification', params);
        }
    },
    resetState({ commit }) {
        commit('RESET_STATE');
    }
}

const getters = {
    getTabularData(state) {
        return state.tabularData;
    },
    getGroups(state) {
        return state.groups;
    },
    getTagList(state) {
        return state.tagsList;
    },
    getListUUID(state) {
        return state.tagsListUuid;
    },
    isLoading(state) {
        return state.loading;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
import Vue from 'vue';
import axios from 'axios'

const state = {
}

const mutations = {
}

const actions = {
    async getDiscipline({ dispatch},params) {
        const data = await axios.post(`${process.env.VUE_APP_API}/assignedlisting`, params);
        dispatch('shared/setDisciplines', data.data.disciplines, {root:true});
    },
    async triggerInstantClassification ({ commit }, offerId) {
        return axios({
            url: process.env.VUE_APP_API + '/offer/summary',
            method: 'post',
            data: {
              offer_id: offerId,
              instant_classification: true  
            }
          });
    }
}

const getters = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
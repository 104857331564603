import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '@/router';

Vue.use(Vuex);

const state = {
    userVendor: null,
}
const mutations = {
    SET_USER_DATA(state, userData) {
        console.log('state, userData',state, userData);
        state.userVendor = userData
        localStorage.setItem('userVendor', JSON.stringify(userData))
    },
    CLEAR_USER_DATA() {
        localStorage.removeItem("userVendor")
        localStorage.clear()
    },
}
const actions = {
    generateOTP({ commit }, credentials) {
        return axios.post(`${process.env.VUE_APP_API}/vendor/otp`, credentials)
    },
    login({ commit }, credentials) {
        return axios.post(`${process.env.VUE_APP_API}/vendor/otp/validate`, credentials)
    },
    logout({ commit }) {
        commit('CLEAR_USER_DATA')
        router.replace({ path: '/vendor' })
    },
}
const getters = {
    loggedIn(state) {
        return !!state.userVendor
    },
    userVendor(state) {
        return state.userVendor
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
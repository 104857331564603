import axios from 'axios'
import Vue from 'vue'

const state = {
    
}
const mutations = {
    
}
const actions = {
    async getVendorsData({ state }, apiParam) {
        return await axios.get(`${process.env.VUE_APP_API_INSIGHTS}/charts/vendor?item_id=${apiParam.item_id}&view=${apiParam.view}&vendor_no=${apiParam.vendor_no}`);
    },
    getVendorListTable({ state }, apiParam) {
        return axios.get(`${process.env.VUE_APP_API_INSIGHTS}/charts/overview?item_id=${apiParam}`);
    },
    sendMRList() {
        let userId = JSON.parse(localStorage.getItem("user"))
        return axios.get(`${process.env.VUE_APP_API_INSIGHTS}/user/mr?user=${userId.user.id}`);
    },
    async getOverView ({ state }, apiParam) {
        console.log('apiParam',apiParam);
        return axios.get(`${process.env.VUE_APP_API_INSIGHTS}/stage/overview?mr=${apiParam.id}&type=${apiParam.type}`);
    },
    async getStageOne ({ state }, apiParam) {
        console.log('apiParam',apiParam);
        if (apiParam.category == 'packages' || apiParam.type == 'packages') {
            return axios.get(`${process.env.VUE_APP_API_INSIGHTS}/stage/msp?package_id=${apiParam.package_id || apiParam.id}&type=${apiParam.category || apiParam.type}`);    
        } else {
            return axios.get(`${process.env.VUE_APP_API_INSIGHTS}/stage/msp?mr=${apiParam.mr_id || apiParam.id}&type=${apiParam.category || apiParam.type}`);
        }
    },
    async getStageTwo ({ state }, apiParam) {
        console.log('apiParam',apiParam);
        return axios.get(`${process.env.VUE_APP_API_INSIGHTS}/stage/vrp?mr=${apiParam.id}&type=${apiParam.type}`);
    },
    async getStageThree ({ state }, apiParam) {
        console.log('apiParam',apiParam);
        return axios.get(`${process.env.VUE_APP_API_INSIGHTS}/stage/vtp?mr=${apiParam.id}&type=${apiParam.type}`);
    },
    async getStageFour ({ state }, apiParam) {
        console.log('apiParam',apiParam);
        return axios.get(`${process.env.VUE_APP_API_INSIGHTS}/stage/tcp?mr=${apiParam.id}&type=${apiParam.type}`);
    },
    async markTagAsComplete ({ state }, apiParam) {
        if (apiParam.category == 'packages') {
            return axios.get(`${process.env.VUE_APP_API_INSIGHTS}/mr/tags/toggle?package_id=${apiParam.package_id}&tag_id=${apiParam.tagId}&type=packages`);
        } else {
            return axios.get(`${process.env.VUE_APP_API_INSIGHTS}/mr/tags/toggle?mr=${apiParam.MR}&tag_id=${apiParam.tagId}`);
        }
    },
}

const getters = {
    
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
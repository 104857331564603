import Vue from 'vue';
import axios from 'axios'

const state = {
    projectDetails: {},
    IDCList: {}
}

const mutations = {
    SET_PROJECT_DETAILS (state, projectDetails) {
        Vue.set(state, 'projectDetails', projectDetails);
    },
    SET_IDC_LIST(state, IDCList) {
        Vue.set(state, 'IDCList', IDCList);
    }
}

const actions = {
    async getDisciplineIDCList ({commit}, payload) {
        if (payload.requisition_id) {
            let queryParams = `requisition_id=${payload.requisition_id}` + (payload.discipline_id ? `&discipline_id=${payload.discipline_id}`: '')+`&is_assignee=${payload.isAssignee}`;
            return await axios.get(`${process.env.VUE_APP_API_MSD}/idcthread?${queryParams}`);
        } else {
            let queryParams = ((payload.mr_id)?`mr_id=${payload.mr_id}`:(payload.package_id)?`package_id=${payload.package_id}`:'' )+ (payload.offer_id ? `&offer_id=${payload.offer_id}`: '') + (payload.discipline_id ? `&discipline_id=${payload.discipline_id}`: '')+`&is_assignee=${payload.isAssignee}`;
            return await axios.get(`${process.env.VUE_APP_API_MSD}/idcthread?${queryParams}`);
        }
    },
    addGeneralIDC({commit}, payload) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/idcthread`, payload);
    },
    async setProjectDetails ({ commit }, payload) {
        console.log('header payload => ', payload);
        
        let queryParams = ''
        if (payload.requisition_id) {
            queryParams = `requisition_id=${payload.requisition_id}`    
            const projectDetails = await axios.get(`${process.env.VUE_APP_REQ}/page/header?page=offerlisting&${queryParams}`);
            commit('SET_PROJECT_DETAILS', projectDetails.data.data);
        } else {
            queryParams = `item=${payload.mr_id || payload.package_id}`+ (payload.offer_id ? `&offer=${payload.offer_id}`: '')
            const projectDetails = await axios.get(`${process.env.VUE_APP_API}/header?page=offerlisting&${queryParams}`);
            commit('SET_PROJECT_DETAILS', projectDetails.data.data);
        }
    },
    async publishIDC({commit}, payload) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/idcthread/publish`, payload);
    },
    editIDC({commit}, payload) {
        return axios.put(`${process.env.VUE_APP_API_MSD}/idcthread`, payload);
    },
    deleteIDC({commit}, payload) {
        return axios.put(`${process.env.VUE_APP_API_MSD}/idcthread`, payload);
    },
    downloadAttachments({commit}, payload) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/idcthread/attachments/export`, payload);
    },
    addFollowupQuery({commit}, payload) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/quickaction/bulk`, payload);
    },
    exportIDC({commit}, param) {
        return axios.get(`${process.env.VUE_APP_API_MSD}/idcthread/export?${param}`);
    },
    addAssigneeResponse({commit}, payload) {
        return axios.put(`${process.env.VUE_APP_API_MSD}/idcthread`, payload);
    },
    publishAssigneeResponse({commit}, payload) {
        return axios.put(`${process.env.VUE_APP_API_MSD}/idcthread/response/publish`, payload);
    },
    downloadAllFiles ({commit}, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/idcthread/attachments/export`, params);
    },
}

const getters = {
    getProjectDetails () {
        return state.projectDetails
    },
    getIDCList() {
        return state.IDCList;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
import axios from 'axios'
import Vue from 'vue'

const state = {
    userInfo: {}
}
const mutations = {
    SET_USER_DATA(state, data) {
        Vue.set(state, 'userInfo', data);
    }
}
const actions = {
    async updateLogo({ state }, params) {
        let data = new FormData();
        if (!Array.isArray(params.logoImage)) {
            data.append('file', params.logoImage);
        }
        data.append('is_logo_enabled', params.enableHeaderLogo ? 'True' : 'False');
        data.append('logo_in_tbe', params.enableExportLogo ? 'True' : 'False');
        return await axios.post(`${process.env.VUE_APP_API}/profile/logo`, data, {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data'
        });
    },
    async getDashboardInfo({ commit, dispatch}) {
        const data = await axios.get(`${process.env.VUE_APP_API}/dashboard`);
        commit('SET_USER_DATA', data.data.user);
        dispatch('shared/setDisciplines', data.data.disciplines, {root:true});
    },
    updateUserInfo({ commit}, params) {
        return axios.post(`${process.env.VUE_APP_API}/modify_user/role`, params);
    }
}

const getters = {
    getUserInfo(state) {
        return state.userInfo;
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
import Vue from 'vue';
import axios from 'axios'

const state = {
    cards:{
        cards: [],
        filters: {},
        items_per_page: 0,
        page_number: 0,
        total_count: 0
    }
}

const mutations = {
    async setCardData(state,payload) {
        // console.log('store payload ---> ', payload.page);
        if (payload.page == 1) {
            // console.log('empty card');
            state.cards.cards = []
        }
        payload.payload.cards.forEach(cd => state.cards.cards.push(cd))
        state.cards.filters = payload.payload.filters
        state.cards.items_per_page = payload.payload.items_per_page
        state.cards.page_number = payload.payload.page_number
        state.cards.total_count = payload.payload.total_count
        // console.log(state.cards);
    }
}

const actions = {
    async getCards({commit}, params) {
       await axios({
        url: process.env.VUE_APP_API + '/notification_cards?user_id='+ params.user.id +'&page_number='+ params.page +'&cards_type=' + params.type,
        method: 'get'
      }).then((res) => {
        commit('setCardData', {payload:res.data.data, page: params.page})
        return 'dataLoaded'
      }).catch((err) => {
        return 'error'
      })
    },
    async getFilteredCards({commit}, params) {
        await axios({
            url: process.env.VUE_APP_API + '/notification_cards?user_id='+ params.user.id +'&page_number='+ params.page + params.payload,
            method: 'get'
        }).then((res) => {
            commit('setCardData', {payload:res.data.data, page: params.page})
            return 'dataLoaded'
        }).catch((err) => {
            return 'error'
        })
    }
   
}

const getters = {
    cardsData: (state) => {
        return state.cards
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox, 
// the second argument is the SVG content stripped of SVG tags
export const logo = ['556 134',`
 
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 44">
  <g id="Group_826" data-name="Group 826" transform="translate(-100 -51)">
    <g id="New-Mocks" transform="translate(99.805 51)">
      <g id="Artboard-5" transform="translate(-1052 -409)">
        <g id="MEW-LOGO" transform="translate(1052 409)">
          <path id="Shape" d="M11.5,10.1A5.018,5.018,0,0,1,6.6,5a4.93,4.93,0,0,1,4.9-5,5.018,5.018,0,0,1,4.9,5.1A4.93,4.93,0,0,1,11.5,10.1Zm19.5,0A5.018,5.018,0,0,1,26.1,5,4.93,4.93,0,0,1,31,0a5.1,5.1,0,0,1,5,5.1A5.015,5.015,0,0,1,31,10.1ZM43,41.6A2.5,2.5,0,0,1,40.6,44a2.456,2.456,0,0,1-2.4-2.5,1.268,1.268,0,0,1,.1-.6V25.4A7.26,7.26,0,0,0,31.2,18a7.26,7.26,0,0,0-7.1,7.4V41.6h0A2.519,2.519,0,0,1,21.6,44a2.6,2.6,0,0,1-2.5-2.4h0V25.4A7.26,7.26,0,0,0,12,18a7.26,7.26,0,0,0-7.1,7.4V40.9c0,.2.1.4.1.6A2.456,2.456,0,0,1,2.6,44,2.433,2.433,0,0,1,.2,41.6h0V24.9A11.821,11.821,0,0,1,11.9,12.5a11.623,11.623,0,0,1,9.6,5.2,11.9,11.9,0,0,1,9.6-5.2A12.107,12.107,0,0,1,43,24.9V41.6Z" fill="#47d6c4" fill-rule="evenodd"/>
        </g>
      </g>
    </g>
    <text id="Rudy_For_Sourcing" data-name="Rudy For
Sourcing" transform="translate(153 78)" fill="#47d6c4" font-size="12" font-family="Roboto-Medium, Roboto" font-weight="500"><tspan x="0" y="0">RUDY FOR</tspan><tspan x="0" y="14">SOURCING</tspan></text>
  </g>
</svg>

`]

import axios from 'axios'
import Vue from 'vue'

const state = {
}
const mutations = {
}
const actions = {
    getFullSnippet ({ state }, param) {
        return axios.post(`${process.env.VUE_APP_API}/offer/full_snippet`, param);
    },
    async getNotifyTimeline({commit}, params) {   
        let query = `offer_id=${params.offer}&category=${params.category}`;
        return axios.get(`${process.env.VUE_APP_API_MSD}/notify_users/internal/timeline?${query}`);
    },
}

const getters = {
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}